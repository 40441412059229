import PropTypes from "prop-types"
import React from "react"
import Helmet from "react-helmet"
import "./page.css"

const Header = ({ siteTitle }) => (
  <header>
    <Helmet>
      <link
        rel="stylesheet"
        href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
        integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
        crossorigin="anonymous"
      />
      <script defer
        src="https://cdn.plaid.com/link/v2/stable/link-initialize.js">
      </script>
    </Helmet>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
