import React from "react"
import { Router } from "@reach/router"
import { login, logout, isAuthenticated, getProfile } from "../utils/auth"
import Header from "../components/header"
import Footer from "../components/footer"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import Button from "react-bootstrap/Button"
import Dashboard from "../components/dashboard"

const Home = ({ user }) => {return null}

const Account = () => {
  if (!isAuthenticated()) {
    login()
    return <p>Redirecting to login...</p>
  }

  const user = getProfile()

  return (
    <div>
      <Header />
      <Navbar className="Menu" variant="light">
        <Navbar.Brand href="#home" className="logoBox">
          <img
            src="https://github.com/dougbluff/LogoStore/blob/master/Logo.PNG?raw=true"
            className="d-inline-block align-top logo"
            alt="ZTS Properties Logo"
          />
        </Navbar.Brand>
        <Nav className="ml-auto">
          <Button
            href="#logout"
            onClick={e => {
              logout()
              e.preventDefault()
            }}
            variant="outline-primary"
          >
            Log Out
          </Button>
        </Nav>
      </Navbar>
      <Router>
        <Home path="/account" user={user} />
      </Router>
      <div className="main">
        <Dashboard />
        </div>
      <Footer />
    </div>
  )
}

export default Account
