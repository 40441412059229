import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"
import Background from "../images/SanAntonio.jpg"
import Cardout from "../components/cardout"

const DashboardSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query StripeQuery {
        stripeCustomer(id: { eq: "cus_F7LjxvPEqkHaJX" }) {
          name
          description
          account_balance
        }
      }
    `}
    render={data => {
      return (
        <div className="dashboard" style={{ backgroundImage: { Background } }}>
          <Card className="text-center" style={{ width: "18rem" }}>
            <Card.Body>
              <Card.Title>{data.stripeCustomer.description}</Card.Title>
              <Card.Title>Welcome Back!</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                How can we help you today?
              </Card.Subtitle>
            </Card.Body>
            <Card.Text>
              Monthly Balance Due: 1607.00 <br />
              <small>
                <em>(This includes rent, processing and reporting)</em>
              </small>
              <br />
              Make a Payment:
            </Card.Text>
            <Cardout />
            <Card.Text>
              <small>
                <em>
                  (Credit Card Payments incur a 5% fee, to avoid that fee speak
                  to your landlord about automatic payments.)
                </em>
              </small>
              <br />
            </Card.Text>
            <Card.Text>Problem with your home?</Card.Text>
            <Button
              href="mailto:support@ztsproperties.com?subject=Maintenance Request&body=Name%20:%0D%0AMaintenance%20Request%20:"
              variant="outline-primary"
            >
              Maintenance Request
            </Button>
          </Card>
        </div>
      )
    }}
  />
)

export default DashboardSection