import React from "react"
import PropTypes from "prop-types"

const Footer = ({ siteTitle }) => (
  <footer>
    © {new Date().getFullYear()}, All Rights Reserved
    {` `}
    <a href="https://www.ztsproperties.com">ZTSProperties</a>
  </footer>
)

Footer.propTypes = {
    siteTitle: PropTypes.string,
  }
  
  Footer.defaultProps = {
    siteTitle: ``,
  }
  
  export default Footer
