import React from "react"
import Button from "react-bootstrap/Button"

const Checkout = class extends React.Component {
  componentDidMount() {
    this.stripe = window.Stripe("pk_live_oriXrScQTqQuhIHnil22a5ro006tlZonuv", {
      betas: ["checkout_beta_4"],
    })
  }

  async redirectToCheckout(event) {
    event.preventDefault()
    const { error } = await this.stripe.redirectToCheckout({
      items: [{ sku: "sku_FJGGP6PmCUTvwz", quantity: 1 }],
      successUrl: `https://www.ztsproperties.com/account/`,
      cancelUrl: `https://www.ztsproperties.com/account/`,
    })

    if (error) {
      console.warn("Error:", error)
    }
  }

  render() {
    return (
      <div className="Payments">
        <Button
          variant="outline-primary"
          onClick={event => this.redirectToCheckout(event)}
        >
          Credit Card
        </Button>
      </div>
    )
  }
}

export default Checkout
